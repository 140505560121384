import { handleErrorWithSentry, Replay, init as initSentry } from '@sentry/sveltekit'
import {
	PUBLIC_ENVIRONMENT,
	PUBLIC_SENTRY_CLIENT_REPLAYS_ON_ERROR_SAMPLE_RATE,
	PUBLIC_SENTRY_CLIENT_REPLAYS_SESSION_SAMPLE_RATE,
	PUBLIC_SENTRY_CLIENT_TRACE_SAMPLE_RATE,
	PUBLIC_SENTRY_DSN
} from '$env/static/public'
import type { HandleClientError } from '@sveltejs/kit'
import { env as envVars } from '$env/dynamic/public'
import env from '$lib/functions/env'

export const _errorHandler = (({ error, event }) => {
	console.error('An error occurred on the client side:', error, event)
}) satisfies HandleClientError

if (env() !== 'development') {
	initSentry({
		dsn: PUBLIC_SENTRY_DSN,
		tracesSampleRate: Number(PUBLIC_SENTRY_CLIENT_TRACE_SAMPLE_RATE),
		replaysSessionSampleRate: Number(PUBLIC_SENTRY_CLIENT_REPLAYS_SESSION_SAMPLE_RATE),
		replaysOnErrorSampleRate: Number(PUBLIC_SENTRY_CLIENT_REPLAYS_ON_ERROR_SAMPLE_RATE),
		integrations: [new Replay()],
		environment: PUBLIC_ENVIRONMENT,
		release: envVars.PUBLIC_BUILD_NUMBER
	})
}

export const handleError = handleErrorWithSentry(_errorHandler)
